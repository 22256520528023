/* iphone 12/mini/SE */
/*
    Only place structural rules here (colors/fonts/etc. go in theme.less).

    margin/padding/display/flex/width/et.al. go here.
*/
html,
body {
  height: 100%;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}
body {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;
}
body > footer {
  height: 30px !important;
}
.navbar-brand {
  width: 300px !important;
}
.dkrow {
  display: flex;
  justify-content: space-around;
}
.dkrow > dk-panel {
  width: 100%;
}
dk-panel {
  margin: 2px;
}
.font-size-h1 {
  font-size: 36px;
}
.font-size-h2 {
  font-size: 30px;
}
.font-size-h3 {
  font-size: 24px;
}
.font-size-h4 {
  font-size: 18px;
}
.font-size-h5 {
  font-size: 14px;
}
.font-size-h6 {
  font-size: 12px;
}
/*
    This file is for calculating inital values for
    the theme-cssvars-defaults file.

    You should not import this file anywhere, just copy
    the generated values.

    Generate values by running lessc in your terminal, i.e.:

        lessc theme-calculations.less > theme-cssvars.less

    (`npm i -g less` to install the lessc tool)

    To find the xx-color-hue values, you can e.g. create a variable with the
    css color you want:

        @foo: #FDB827

    and then use pycharm/vscode's color inspector to change from rgb to hsl.
*/
:root {
  --basecolor: #d9c18c;
  --background-color: #fefefe;
  --widget-background-color: #fdfdfd;
  --widget-border-color: #eeeeee;
  --neutral-color-3: #f7f7f7;
  --neutral-color-5: #f3f3f3;
  --neutral-color-10: #e8e8e8;
  --neutral-color-20: #d2d2d2;
  --neutral-color-40: #a6a6a6;
  --neutral-color-60: #7a7a7a;
  --neutral-color-80: #4e4e4e;
  --neutral-color-100: #222222;
  --theme-color-10: #f5f0e6;
  --theme-color-20: #ebe1cc;
  --theme-color-40: #cfb887;
  --theme-color-60: #c2a566;
  --theme-color-80: #ad8633;
  --theme-color-100: #996900;
  --contrast-color-10: #e6f5f1;
  --contrast-color-20: #ccebe3;
  --contrast-color-40: #87cfbe;
  --contrast-color-60: #66c2ac;
  --contrast-color-80: #33ad90;
  --contrast-color-100: #009975;
}
body.dark {
  --basecolor: #d9c18c;
  --background-color: #1e1e1e;
  --widget-background-color: #1d1d1d;
  --neutral-color-3: #252525;
  --neutral-color-5: #292929;
  --neutral-color-10: #343434;
  --neutral-color-20: #4b4b4b;
  --neutral-color-40: #787878;
  --neutral-color-60: #a5a5a5;
  --neutral-color-80: #d2d2d2;
  --neutral-color-100: #ffffff;
  --theme-color-10: #996900;
  --theme-color-20: #ad8633;
  --theme-color-40: #c2a566;
  --theme-color-60: #cfb887;
  --theme-color-80: #ebe1cc;
  --theme-color-100: #f5f0e6;
  --contrast-color-10: #009975;
  --contrast-color-20: #33ad90;
  --contrast-color-40: #66c2ac;
  --contrast-color-60: #87cfbe;
  --contrast-color-80: #ccebe3;
  --contrast-color-100: #e6f5f1;
}
.navbar-brand {
  border-right: 1px solid transparent;
  font-size: 14px;
}
@media screen and (min-width: 768px) {
  .navbar-brand {
    border-right-color: white;
  }
}
.navbar-inverse a {
  color: var(--neutral-color-3);
}
.tctr-filter.selected {
  background: white;
  color: black;
}
.tctr-filter:hover {
  background: white;
  color: black;
  cursor: pointer;
}
nav.left-nav .left-nav-content {
  padding: 0 14px 40px;
  overflow: auto;
}
nav.left-nav .left-nav-content .toolbar-section > a {
  display: block;
}
nav.left-nav .left-nav-content .submenu {
  padding-left: 1em;
}
nav.left-nav .left-nav-content menu[type=toolbar] {
  margin: 14px 0 0 0;
  padding: 0;
}
nav.left-nav .left-nav-content menu[type=toolbar] [type=menu] {
  cursor: pointer;
  margin-bottom: 5px;
}
nav.left-nav .left-nav-content menu[type=toolbar] [type=menu] small {
  color: #999;
}
nav.left-nav .left-nav-content menu[type=toolbar] [type=menu] a {
  outline: none;
}
nav.left-nav .left-nav-content menu[type=toolbar] [type=menu]:hover,
nav.left-nav .left-nav-content menu[type=toolbar] [type=menu].selected {
  background-color: #353535;
  margin-left: -14px;
  margin-right: -14px;
  padding-left: 14px;
}
nav.left-nav .left-nav-content menu[type=toolbar] [type=menu].selected {
  background-color: black;
  border: 3px solid black;
  border-right-width: 0;
  border-left-width: 0;
}
nav.left-nav .left-nav-content menu[type=toolbar] [type=menu].selected:after {
  display: block;
  border: 12px solid transparent;
  border-right-color: white;
  float: right;
  position: relative;
  top: -1px;
  width: 0;
  height: 0;
  z-index: 2;
  content: "";
}
nav.left-nav .left-nav-content menu[type=toolbar] .home-menu-item.selected:after {
  border-width: 16px;
}
nav.left-nav .left-nav-content h3 {
  color: #cbcbcb;
  font-size: 1.5rem;
  margin: 12px 0 5px;
}
nav.left-nav .left-nav-content a.pbtn {
  border-color: #999;
  border-radius: 5px;
}
nav.left-nav .left-nav-content a.pbtn > dk-icon,
nav.left-nav .left-nav-content a.pbtn > span.title {
  color: #999;
}
nav.left-nav .left-nav-content a.pbtn:visited {
  color: #666;
}
nav.left-nav .left-nav-content a.pbtn:hover {
  border-color: #bbb;
}
nav.left-nav .left-nav-content a.pbtn:hover > span.title {
  color: #bbb;
}
nav.left-nav .left-nav-content a.pbtn:hover:visited {
  color: #bbb;
}
nav.left-nav .left-nav-content .person .plabel {
  font-size: 80%;
  color: #ccc;
}
nav.left-nav .left-nav-content .person .pvalue {
  line-height: 100%;
}
nav.left-nav .left-nav-content .person .person-main-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
nav.left-nav .left-nav-content .person .person-main-info .person-demographics {
  display: flex;
  flex-direction: column;
}
nav.left-nav .left-nav-content .person .person-main-info .person-demographics .person-info {
  margin-bottom: 1rem;
}
nav.left-nav .left-nav-content .person .person-secondary-info {
  font-size: 80%;
}
nav.left-nav .left-nav-content .person .person-secondary-info .edit-person-info {
  visibility: hidden;
}
nav.left-nav .left-nav-content .person .person-secondary-info:hover .edit-person-info {
  visibility: visible;
}
.modal-page.ok {
  background-color: #3cdbc0;
}
.modal-page.danger {
  background-color: #e45d50;
}
.modal-page.error {
  background-color: #e45d50;
}
.modal-page.warning {
  background-color: #e6a65d;
}
.modal-page.info {
  background-color: #5bc2e7;
}
.modal-page .modal-box {
  display: flex;
  position: relative;
  flex-direction: column;
  align-content: space-between;
  border: 1px solid #999;
  border-radius: 6px;
  background-color: var(--background-color);
  margin-top: 5%;
  max-width: 800px;
  padding: 60px;
}
@media screen and (max-width: 767px) {
  .modal-page .modal-box {
    max-width: calc(100% - 2 * 10px);
    max-height: calc(100% - 2 * 10px);
    margin-top: 10px;
    padding: 60px / 2;
  }
}
.modal-page .modal-box > header {
  border-bottom: 1px solid var(--neutral-color-20);
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.modal-page .modal-box > header .logo {
  max-width: 400px;
}
@media screen and (max-width: 576px) {
  .modal-page .modal-box > header .logo {
    max-width: 100%;
  }
}
.modal-page .modal-box > footer hr {
  width: 30%;
  text-align: left;
}
.price-table {
  max-width: 750px;
}
.price-table thead {
  font-size: 150%;
  font-weight: bold;
  height: 70px;
}
.price-table .price {
  text-align: right;
  font-family: 'Courier New', Courier, monospace;
  white-space: nowrap;
  max-width: 11ex;
}
.price-table .kr {
  max-width: 4ex;
}
@keyframes heartbeat {
  to {
    transform: scale(1.4);
  }
}
.heart-beat {
  animation: heartbeat 0.5s 6 alternate;
  transform-origin: center;
}
.tctr-filter-box {
  columns: 4;
}
.center-box {
  flex-grow: 1;
  display: flex;
}
.center-box > main {
  flex-grow: 1;
  padding-top: 14px;
  padding-bottom: 30px;
  overflow: auto;
}
.center-box > .left-nav {
  background-color: #555;
  color: #efefef;
  width: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.center-box > .left-nav > .left-nav li {
  border-bottom: 1px solid #999;
}
.center-box > .left-nav a {
  color: #efefef !important;
}
.center-box > .left-nav .btn-default {
  background-color: #555;
}
dk-panel .panel-body {
  overflow: hidden;
}
dk-panel footer.panel-footer {
  display: none;
}
a.dashboard-btn {
  white-space: normal;
  width: 120px;
  height: 110px;
  margin: 0 4px 4px 0;
}
.button-cell {
  float: left;
}
.button-cell a.dashboard-btn {
  white-space: normal;
  width: 120px;
  height: 120px;
  margin: 0 4px 4px 0;
  display: inline-flex;
  flex-direction: column;
}
.button-cell a.dashboard-btn svg:not(:root) {
  height: 70%;
  align-self: center;
}
.admin .button-cell a.dashboard-btn svg:not(:root) {
  height: 100% !important;
}
.documents {
  color: #888;
}
.testleader {
  font-size: 14px !important;
}
.testleader dk-icon,
.testcenter dk-icon {
  color: #454545 !important;
}
.testleader ul.fa-ul li {
  margin-bottom: 14px;
}
.documents .dashboard-btn {
  width: auto;
  height: auto;
}
.testcenter .dashboard-btn {
  padding: 20px;
}
dk-panel > .panel-heading .headingtext {
  font-size: 14px !important;
}
.second.row.information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1325px) {
  .second.row.information {
    flex-direction: row;
  }
  .second.row.information .calendar {
    width: 45%;
  }
  .second.row.information .documents {
    width: 45%;
  }
}
.documents h4 {
  display: inline;
}
.documentation-btn {
  width: 100%;
  margin-bottom: 4px;
  text-align: left;
}
.stack {
  position: relative;
  display: inline-block;
}
.stack img {
  width: 41px;
  height: 41px;
}
.stack dk-icon {
  position: absolute;
  top: 41px / 2 - 41px / 2.7777 / 2;
  left: 12.11979335px;
  font-size: 41px / 2.7777;
  line-height: 100%;
}
.panel-admin {
  border-color: #e60026;
}
.panel-admin > .panel-heading {
  color: white;
  background-color: red;
  border-color: #e60026;
}
.panel-admin > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #e60026;
}
.panel-admin > .panel-heading .badge {
  color: red;
  background-color: white;
}
.panel-admin > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #e60026;
}
.panel-admin > .panel-heading {
  color: white;
  background-color: red;
  border-color: #e60026;
}
.panel-admin > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #e60026;
}
.panel-admin > .panel-heading .badge {
  color: red;
  background-color: white;
}
.panel-admin > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #e60026;
}
.panel-tl {
  border-color: #e66e00;
}
.panel-tl > .panel-heading {
  color: white;
  background-color: orange;
  border-color: #e66e00;
}
.panel-tl > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #e66e00;
}
.panel-tl > .panel-heading .badge {
  color: orange;
  background-color: white;
}
.panel-tl > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #e66e00;
}
.panel-tl > .panel-heading {
  color: white;
  background-color: orange;
  border-color: #e66e00;
}
.panel-tl > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #e66e00;
}
.panel-tl > .panel-heading .badge {
  color: orange;
  background-color: white;
}
.panel-tl > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #e66e00;
}
.panel-ka {
  border-color: #97bc90;
}
.panel-ka > .panel-heading {
  color: #333;
  background-color: #a0c6a0;
  border-color: #97bc90;
}
.panel-ka > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #97bc90;
}
.panel-ka > .panel-heading .badge {
  color: #a0c6a0;
  background-color: #333;
}
.panel-ka > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #97bc90;
}
.panel-ka > .panel-heading {
  color: #333;
  background-color: #a0c6a0;
  border-color: #97bc90;
}
.panel-ka > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #97bc90;
}
.panel-ka > .panel-heading .badge {
  color: #a0c6a0;
  background-color: #333;
}
.panel-ka > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #97bc90;
}
