@import "theme-vars";


.navbar-brand {
    border-right: 1px solid transparent; 
    font-size: 14px;
}

@media screen and (min-width: @screen-sm-min) {
    .navbar-brand { border-right-color: white; }
}

.navbar-inverse a { color: @text-color-inverse; }

.tctr-filter.selected {
    background: white;
    color: black;
}

.tctr-filter:hover {
    background: white;
    color: black;
    cursor: pointer;
}

