
nav.left-nav {
    .left-nav-content {
        padding: 0 14px 40px;
        overflow: auto;

        .toolbar-section > a {
            display: block;
        }

        .submenu { padding-left: 1em; }

        menu[type=toolbar] {
            margin: 14px 0 0 0;
            padding: 0;

            [type=menu] {
                cursor: pointer;
                margin-bottom: 5px;

                small { color: #999; }
                a { outline: none; }

                &:hover,
                &.selected {
                    background-color: #353535;
                    margin-left: -14px; margin-right: -14px;
                    padding-left: 14px;
                }

                &.selected {
                    background-color: black;
                    border: 3px solid black;
                    border-right-width: 0;
                    border-left-width: 0;

                    &:after {
                        display: block;
                        border: 12px solid transparent;
                        border-right-color: white;
                        float: right; position: relative;
                        top: -1px;
                        width: 0; height: 0;
                        z-index: 2;
                        content: "";
                    }
                }

            }
            .home-menu-item.selected:after {
                border-width: 16px;
            }
        }

        h3 { color: #cbcbcb; font-size: 1.5rem; margin: 12px 0 5px; }

        a.pbtn {
            border-color: #999;
            border-radius: 5px;

            & > dk-icon,
            & > span.title { color: #999; }

            &:visited { color: #666; }
            &:hover {
                border-color: #bbb;

                & > span.title { color: #bbb; }
                &:visited { color: #bbb; }
            }
        }

        .person {
            .plabel { font-size: 80%; color: #ccc; }
            .pvalue { line-height: 100%; }

            .person-main-info {
                display: flex;
                flex-direction: row;
                align-items: center;

                .person-demographics {
                    display: flex;
                    flex-direction: column;

                    .person-info { margin-bottom: 1rem; }
                }
            }
            .person-secondary-info {
                font-size: 80%;
                .edit-person-info { visibility: hidden; }
                &:hover {
                    .edit-person-info { visibility: visible; }
                }
            }
        }
    }
}
