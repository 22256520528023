@import "theme-vars";

.modal-page {
  &.ok {
    background-color: @ok-color;
  }

  &.danger {
    background-color: @danger-color;
  }

  &.error {
    background-color: @error-color;
  }

  &.warning {
    background-color: @warning-color;
  }

  &.info {
    background-color: @info-color;
  }

  .modal-box {
    @padding: 60px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-content: space-between;
    border: 1px solid #999;
    border-radius: 6px;
    background-color: @background-color;

    margin-top: 5%;
    max-width: 800px;
    padding: @padding;
    //min-height: 400px;

    @media screen and (max-width: @screen-xs-max) {
      @margin: 10px;
      max-width: calc(100% - 2 * @margin);
      max-height: calc(100% - 2 * @margin);
      margin-top: @margin;
      padding: @padding / 2;

      //header .logo {
      //    max-width: 400px;
      //}
    }

    > header {
      border-bottom: 1px solid @neutral-color-20;
      padding-bottom: @gutter-width;
      margin-bottom: @gutter-width;

      .logo {
        max-width: 400px;
        @media @mobile {
          max-width: 100%;
        }

      }
    }

    > footer {
      hr {
        width: 30%;
        text-align: left;
      }
    }
  }
}
