/*
    This file is for calculating inital values for
    the theme-cssvars-defaults file.

    You should not import this file anywhere, just copy
    the generated values.

    Generate values by running lessc in your terminal, i.e.:

        lessc theme-calculations.less > theme-cssvars.less

    (`npm i -g less` to install the lessc tool)

    To find the xx-color-hue values, you can e.g. create a variable with the
    css color you want:

        @foo: #FDB827

    and then use pycharm/vscode's color inspector to change from rgb to hsl.
*/
:root {
  --basecolor: #d9c18c;
  --background-color: #fefefe;
  --widget-background-color: #fdfdfd;
  --widget-border-color: #eeeeee;

  --neutral-color-3: #f7f7f7;
  --neutral-color-5: #f3f3f3;
  --neutral-color-10: #e8e8e8;
  --neutral-color-20: #d2d2d2;
  --neutral-color-40: #a6a6a6;
  --neutral-color-60: #7a7a7a;
  --neutral-color-80: #4e4e4e;
  --neutral-color-100: #222222;
  --theme-color-10: #f5f0e6;
  --theme-color-20: #ebe1cc;
  --theme-color-40: #cfb887;
  --theme-color-60: #c2a566;
  --theme-color-80: #ad8633;
  --theme-color-100: #996900;
  --contrast-color-10: #e6f5f1;
  --contrast-color-20: #ccebe3;
  --contrast-color-40: #87cfbe;
  --contrast-color-60: #66c2ac;
  --contrast-color-80: #33ad90;
  --contrast-color-100: #009975;
}
body.dark {
  --basecolor: #d9c18c;
  --background-color: #1e1e1e;
  --widget-background-color: #1d1d1d;
  --neutral-color-3: #252525;
  --neutral-color-5: #292929;
  --neutral-color-10: #343434;
  --neutral-color-20: #4b4b4b;
  --neutral-color-40: #787878;
  --neutral-color-60: #a5a5a5;
  --neutral-color-80: #d2d2d2;
  --neutral-color-100: #ffffff;
  --theme-color-10: #996900;
  --theme-color-20: #ad8633;
  --theme-color-40: #c2a566;
  --theme-color-60: #cfb887;
  --theme-color-80: #ebe1cc;
  --theme-color-100: #f5f0e6;
  --contrast-color-10: #009975;
  --contrast-color-20: #33ad90;
  --contrast-color-40: #66c2ac;
  --contrast-color-60: #87cfbe;
  --contrast-color-80: #ccebe3;
  --contrast-color-100: #e6f5f1;
}
