@import "media";
@import "layout";
@import "typography";
@import "theme-vars";
@import "theme";
@import "admin-left-menu";
@import "modal";
@import "pricelist";
@import "animation";


.tctr-filter-box {
    columns: 4;
}






.center-box {
    flex-grow: 1;
    display: flex;

    & > main {
        flex-grow: 1;
        padding-top: 14px;
        padding-bottom: 30px;
        overflow: auto;
    }

    & > .left-nav {
        background-color: #555;
        color: #efefef;
        width: 300px;
        min-width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > .left-nav li {
            border-bottom: 1px solid #999;
        }

        a {
            color: #efefef !important;
        }
        .btn-default {
            background-color: #555;
        }
    }
}


dk-panel {
    .panel-body { overflow: hidden; }
    footer.panel-footer { display: none; }
}

a.dashboard-btn {
    white-space: normal;
    width: 120px;
    height: 110px;
    margin: 0 4px 4px 0;
}

.button-cell {
    float: left;

    a.dashboard-btn {
        white-space: normal;
        width: 120px;
        height: 120px;
        margin: 0 4px 4px 0;
        display: inline-flex;
        flex-direction: column;

        svg:not(:root) {
            height: 70%;
            align-self: center;
        }
    }
}
.admin {
    .button-cell a.dashboard-btn svg:not(:root) {
        height: 100% !important;
    }
}

.documents { color: #888; }

.testleader { font-size: 14px !important; }

.testleader dk-icon, .testcenter dk-icon { color: #454545 !important; }

.testleader ul.fa-ul li { margin-bottom: 14px; }

.documents .dashboard-btn {
    width: auto; height: auto;
}

.testcenter .dashboard-btn {
    padding: 20px;
}

dk-panel > .panel-heading .headingtext { font-size: 14px !important; }

.second.row.information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1325px) {
        flex-direction: row;
        .calendar { width: 45%; }

        .documents { width: 45%; }
    }
}

.documents {
    h4 { display: inline; }
}

//@stack-img-size: 38px;
@stack-img-size: 41px;
@stack-middle: @stack-img-size / 2;
@stack-ikn-size: @stack-img-size / 2.7777;
@stack-ikn-pos: @stack-middle - @stack-ikn-size / 2;

.documentation-btn {
    width: 100%;
    margin-bottom: 4px;
    text-align: left;
}

.stack {
    position: relative;
    display: inline-block;

    img {
        width: @stack-img-size;
        height: @stack-img-size;
    }
    dk-icon {
        position: absolute;
        top: @stack-ikn-pos;
        left: (@stack-ikn-pos - 1);
        font-size: @stack-ikn-size; line-height: 100%;
    }
}

.panel-variant(@border; @heading-text-color; @heading-bg-color; @heading-border) {
    border-color: @border;

    & > .panel-heading {
        color: @heading-text-color;
        background-color: @heading-bg-color;
        border-color: @heading-border;

        + .panel-collapse > .panel-body {
            border-top-color: @border;
        }
        .badge {
            color: @heading-bg-color;
            background-color: @heading-text-color;
        }
    }
    & > .panel-footer {
        + .panel-collapse > .panel-body {
            border-bottom-color: @border;
        }
    }
}

.panel-variant(@border; @heading-text-color; @heading-bg-color; @heading-border) {
    border-color: @border;

    & > .panel-heading {
        color: @heading-text-color;
        background-color: @heading-bg-color;
        border-color: @heading-border;

        + .panel-collapse > .panel-body {
            border-top-color: @border;
        }
        .badge {
            color: @heading-bg-color;
            background-color: @heading-text-color;
        }
    }
    & > .panel-footer {
        + .panel-collapse > .panel-body {
            border-bottom-color: @border;
        }
    }
}

@staff-text: white;
@staff-bg: red;
@staff-border: darken(spin(@staff-bg, -10), 5%);

.panel-admin {
    .panel-variant(@staff-border, @staff-text, @staff-bg, @staff-border)
}

@tl-text: white;
@tl-bg: orange;
@tl-border: darken(spin(@tl-bg, -10), 5%);

.panel-tl {
    .panel-variant(@tl-border, @tl-text, @tl-bg, @tl-border)
}

@ka-text: #333;
@ka-bg: desaturate(lighten(green, 45%), 75%);
@ka-border: darken(spin(@ka-bg, -10), 5%);

.panel-ka {
    .panel-variant(@ka-border, @ka-text, @ka-bg, @ka-border)
}
