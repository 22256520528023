/*
    Only place structural rules here (colors/fonts/etc. go in theme.less).

    margin/padding/display/flex/width/et.al. go here.
*/

html, body { height: 100%; width: 100%; margin: 0 !important; padding: 0 !important; }

body {
    display: flex; justify-content: space-between;
    flex-direction: column;
    overflow: auto;
    &> footer { height: 30px !important; }
}


// .navbar .navbar-header .navbar-brand
.navbar-brand {
    width: 300px !important;
}

.dkrow {
    display: flex;
    justify-content: space-around;
}

.dkrow > dk-panel {
    width: 100%;
}


dk-panel {
    margin: 2px;
}