.price-table {
    max-width: 750px;

    thead {
        font-size: 150%;
        font-weight: bold;
        height: 70px;
    }
    .price {
        text-align: right;
        font-family: 'Courier New', Courier, monospace;
        white-space: nowrap;
        max-width: 11ex;
    }
    .kr { max-width: 4ex; }
}
